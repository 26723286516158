// 返回枚举数组列表的方法
export function getEnumList(obj) {
    const list = [];
    for (let key in obj) {
        if (!key.startsWith('get')) {
            list.push(obj[key]);
        }
    }
    return list;
}

/**
 * 检测传入的值 是否为空
 * @param value 需要检测的参数
 * @return {boolean} 为空： true，不为空： false；
*/
export function isEmpty(value) {
    if ((typeof value === 'string' && value.trim() == '')  || typeof (value) === 'undefined' || value === 'undefined' || value === null) {
        return true;
    }
    return false;
}

/**
 * 生成uuid
*/
export function generateUUID() {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
}

/**
 * 过滤掉不需要的字段
*/
export function omitField(obj, notFields) {
    let newObj = {}
    const objKeys = Object.keys(obj)
    objKeys.forEach(key => {
        if (!notFields.includes(key)) {
            newObj[key]  = obj[key]
        }
    })
    return newObj
}